import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {HttpProjectsParams, httpGetProjects} from '@/features/project/services/project.http'

export const useProjectsQuery = (params: Omit<HttpProjectsParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [
            QUERY_KEYS.PROJECTS,
            params.orderBy,
            params.orderDirection,
            params.search,
            params.projectTypeIds,
            params.successManagerIds,
            params.teamLeadIds,
            params.productLeadIds,
            params.status,
            params.customerIds,
            params.needWorkProgressStatus
        ],
        queryFn: ({pageParam = 1}) =>
            httpGetProjects({
                ...params,
                status: params.status,
                page: pageParam
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
