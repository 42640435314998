import {SearchLgIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useSalsTable} from '@/features/sal/services/queries/useSalsTable'
import {SalsFilters} from '@/features/sal/components/sals-filters/SalsFilters'
import React, {useRef} from 'react'
import InputText from '@/components/commons/input-text/InputText'
import {SalsTable} from '@/features/sal/components/sals-table/SalsTable'
import Button from '@components/ui/button/Button.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import {httpSalsAnalyze} from '@/features/sal/services/sal.http.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useTheme} from 'styled-components'

export const Sals: React.FC = () => {
    const {t} = useTranslation()
    const salsQuery = useSalsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const {palette} = useTheme()
    const salBudgetReport = useAsync()

    const onClickCreateReport = () => {
        salBudgetReport.run(httpSalsAnalyze())
    }

    return (
        <PageLayout
            title={`${t('sal:title')}`}
            sideHeading={
                <Flexbox gap={2} align="center">
                    <InputText
                        defaultValue={salsQuery.searchValue}
                        onChange={e => salsQuery.onSearch(e.currentTarget.value)}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />
                    <SalsFilters />
                    <Button variant={'primary'} disabled={salBudgetReport.isLoading} onClick={onClickCreateReport}>
                        {t('sal:create_report')}
                        {salBudgetReport.isLoading && <Spinner color={palette.neutral[900]} size={24} />}
                    </Button>
                </Flexbox>
            }
        >
            <SalsTable
                data={salsQuery.remappedData}
                isChangingPage={salsQuery.isFetchingNextPage}
                isError={salsQuery.isError}
                isLoading={salsQuery.isLoading}
                onResetSearchCb={() => salsQuery.onResetSearch(searchRef)}
                onChangePage={salsQuery.fetchNextPage}
                sorter={salsQuery.sorter}
                searchValue={salsQuery.searchValue}
                filterValue={salsQuery.filterValue}
            />
        </PageLayout>
    )
}

Sals.displayName = 'Sals'
